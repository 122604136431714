<template>
  <div>
    <section>
      <!-- <v-parallax dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="600"> -->
      <v-parallax
        dark
        class="dark--gradient"
        src="@/assets/images/VentureWallet Landing Background.jpg"
        height="600"
      >
        <v-row align="center">
          <v-col align="center" class="white--text">
            <span v-if="isLocalServer">DEVELOPMENT</span>
            <v-img
              src="@/assets/images/VentureWallet_Wide_Transparent.png"
              alt="VentureWallet"
              max-width="600px"
              contain
            />
            <div class="subheading mb-4 text-center">
              Manage your startup investments like the pros
            </div>
            <v-btn class="primary" dark large to="/register">Get Started</v-btn>
          </v-col>
        </v-row>
      </v-parallax>
    </section>

    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">
              The best way to manage your startup investments
            </h2>
            <v-img
              src="@/assets/images/VentureWallet_android_whiteback.jpg"
              height="500"
              contain
            />
            <!-- <span class="subheading">Crowdfunding investors, angel investors, and venture capital</span> -->
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2"
                      >trending_up</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Portfolio Management</div>
                  </v-card-title>
                  <v-card-text
                    >Track all your startup investments in one place. Sort based
                    on any criteria, review your deal notes, and track
                    valuations. Get insights into your portfolio's industry
                    exposure, track your progress against investor limits, and
                    more.</v-card-text
                  >
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2"
                      >track_changes</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Deal Flow Tracker</div>
                  </v-card-title>
                  <v-card-text
                    >Keep track of all the investments in your funnel, from
                    initial discovery through due diligence and investment
                    commitment. Use our templates and checklists for screening
                    deals and keeping objective notes to refine your screening
                    process.</v-card-text
                  >
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="blue--text text--lighten-2"
                      >attach_money</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Tax Tracking</div>
                  </v-card-title>
                  <v-card-text
                    >Whether you are investing $10 or $1,000 per deal, the IRS
                    requires you to keep track of certain criteria, especially
                    if you hope to take advantage of tax benefits in the future.
                    We provide an easy way to track this info over time so it is
                    easy to reference when needed years down the
                    line.</v-card-text
                  >
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section class="pt-5">
      <div class="text-center mt-7">
        <p class="headline">
          Investing in startups has been off-limits for the average investor -
          until now
        </p>
        <em>
          For free investor courses, content, and tools, check out our education
          site at
          <a href="https://crowdwise.org" target="_blank">Crowdwise.org</a>
        </em>
      </div>
      <v-img
        src="@/assets/images/VentureWallet_Mac_Demo_600px.jpg"
        height="600"
        contain
      />
    </section>

    <section
      style="min-height: 250px"
      class="pa-5 dark--gradient lighten-1 white--text"
    >
      <v-container fluid>
        <v-row align="center" style="height: 250px">
          <v-col align="center">
            <p class="display-1">
              Track and manage your startup portfolio like the pros.
            </p>
            <v-btn class="mt-10 primary" dark large to="/register"
              >Create Free Account</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-footer color="white">
      <v-layout row wrap align-center justify-center>
        <v-col xs12>
          <div class="font-weight-light">
            © 2020 All rights reserved, Crowdwise, LLC dba VentureWallet
          </div>
        </v-col>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  computed: {
    isLocalServer() {
      return location.hostname === "localhost" ? true : false;
    },
  },
};
</script>